/* eslint-disable max-len */
/* eslint-disable brace-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import axios from 'axios';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { themeStyles } from '../../styles';
import { myStyles } from './styles';
import LeadFormLoading from './leadFormLoading';
import { constructPayloadForSubmitLead } from './submitForm';
import ThemeContext from '../../utils/theme-context';

// const endpoint = 'https://gprfl2e0zl.execute-api.us-west-2.amazonaws.com/v1'; // stg url

export default function ThankYou({ context, ...others }) {
  // const endpoint = process.env.GATSBY_LEAD_FORM_SUBMIT_URL;
  // const endpoint = `${process.env.GATSBY_LEAD_FORM_SUBMIT_LEAD_ENDPOINT}`;
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  // const themeClass = themeStyles();
  const [loading, setLoading] = useState(true);

  const submitAPI = async (endpoint) => {
    const lead = JSON.parse(localStorage.getItem('lead'));
    const data = constructPayloadForSubmitLead(lead, others);
    try {
      await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      });
      setLoading(false);
    }
    catch (err) {
      navigate('/error');
    }
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      switch (context.flowOrder) {
        case 'Selling Only':
          navigate('/customer-info');
          break;
        case 'Buying Only':
          navigate('/customer-info');
          break;
        case 'Buying, Selling':
          navigate('/service-selection');
          break;
        case 'Selling, Buying':
          navigate('/service-selection');
          break;
        default:
          navigate('/');
      }
    }
    window.addEventListener('popstate', () => {
      localStorage.removeItem('lead');
      switch (context.flowOrder) {
        case 'Selling Only':
          navigate('/customer-info');
          break;
        case 'Buying Only':
          navigate('/customer-info');
          break;
        case 'Buying, Selling':
          navigate('/service-selection');
          break;
        case 'Selling, Buying':
          navigate('/service-selection');
          break;
        default:
          navigate('/');
      }
    });
    const getEndpointAndCall = async () => {
      const endpoint = context.envVars.LEAD_FORM_SUBMIT_URL || `${process.env.GATSBY_LEAD_FORM_SUBMIT_LEAD_ENDPOINT}`;
      loading && submitAPI(endpoint);
    };
    getEndpointAndCall();
  }, [loading]);

  const closeModal = () => {
    navigate('/');
    localStorage.removeItem('lead');
    localStorage.removeItem('sellApt');
    localStorage.removeItem('buyLocation');
    localStorage.removeItem('sellLocation');
  };
  return (
    <>

      {loading
      && <LeadFormLoading />}
      {!loading && (
        <Container id="lead-form-thankyou" className={classes.root}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {/* <div className={classes.leadFormHelperBar}>
              <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>

              <Paper className={clsx(classes.paper, classes.customCenterAlign)}>
                {context?.screenTitle?.raw && <Typography className={classes.nestedHeading} variant="h2" id="lead-form-thankyou-h2">{documentToReactComponents(JSON.parse(context?.screenTitle?.raw))}</Typography>}
                {/* <Typography variant="h2">Thank you for connecting with us!</Typography> */}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} alignItems="center" className={classes.wordWrap}>
              <Paper className={clsx(classes.paper, classes.customCenterAlign)}>
                {context?.thxBodyText?.raw && <Typography variant="h4" id="lead-form-thankyou-h4">{documentToReactComponents(JSON.parse(context?.thxBodyText?.raw))}</Typography>}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <Button id="lead-form-thankyou-close-btn" onClick={closeModal} size="large" variant="contained" className={globalThemeCss.containedBtn}>{context.thxCloseButtonLabel}</Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
