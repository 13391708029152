import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import { Card, CardContent, Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { LeadFormContext } from '../../utils/leadForm-context';
import { themeStyles } from '../../styles';
import { myStyles } from './styles';
import ThemeContext from '../../utils/theme-context';

export default function ConfirmationScreen({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const leadFormStore = useContext(LeadFormContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [checkBox, setcheckBox] = useState(false);
  const { ESIGN, ABAD } = checkBox;
  const error = [ESIGN, ABAD].filter((v) => v).length !== 2;
  const compBasicModalLeadForm = useState(context.appData.compBasicModalLeadForm);
  const [sellAddress, setSellAddress] = useState('');
  const [lead, setLead] = useState('');

  const getLeadDetails = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
    return getLead;
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      switch (context.flowOrder) {
        case 'Selling Only':
          navigate('/customer-info');
          break;
        case 'Buying Only':
          navigate('/customer-info');
          break;
        case 'Buying, Selling':
          navigate('/service-selection');
          break;
        case 'Selling, Buying':
          navigate('/service-selection');
          break;
        default:
          navigate('/');
      }
    }
    getLeadDetails();
    const getLead = JSON.parse(localStorage.getItem('lead'));
    // getLead.customQuestion && setValues(getLead.customQuestion || {})
    setLead(getLead);
    if ( getLeadDetails().operationType === 'SELL' || getLeadDetails().operationType === 'BUYSELL') {
      if(getLeadDetails().sellingLocation.sellApt){
       getSellAddress();
      }
      else{
        setSellAddress(getLeadDetails().sellingLocation.selectedOption);
      }
  };
  }, []);

  const handleChangeEsign = (event) => {
    setcheckBox({ ...checkBox, [event.target.name]: event.target.checked });
  };
  const handleChangeAbad = (event) => {
    setcheckBox({ ...checkBox, [event.target.name]: event.target.checked });
  };
  const getSellAddress =() => {
    const sellLocation =getLeadDetails().sellingLocation.selectedOption.split(',');
    const aptNo = ' ' + getLeadDetails().sellingLocation.sellApt;
    sellLocation.splice(sellLocation.length-3, 0, aptNo);
    setSellAddress(sellLocation.toString());
  };
  // const userdetails = () => {
  //   const userInfo = getLeadDetails();
  //       console.log("Text",userInfo.customerInfo);
  //   return userInfo.customerInfo
  // }
  // const userLocation = () => {
  //   const addr = getLeadDetails();
  //   if (addr.OperationType=='SELL') {
  //       return addr.sellingLocation.address
  //   }
  //   if (addr.OperationType=='BUY') {
  //       return addr.BuyingLocation.address
  //   }
  // }
  console.log(context, 'Context ------ HEre');
  console.log(lead, 'leadData');
  const GoBack = () => {
    if (context.flowOrder === 'Buying, Selling') {
      if ((lead.operationType === 'BUYSELL' || lead.operationType === 'BUY' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
        navigate('/legal-residence');
      } else if ((lead.operationType === 'BUYSELL' || lead.operationType === 'BUY' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else if (lead.operationType === 'BUYSELL' || lead.operationType === 'SELL') {
        navigate('/sell-location');
      } else if (lead.operationType === 'BUY') {
        navigate('/buy-location');
      }
    } else if (context.flowOrder === 'Selling, Buying') {
      if ((lead.operationType === 'BUYSELL' || lead.operationType === 'BUY' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
        navigate('/legal-residence');
      }else if ((lead.operationType === 'BUYSELL' || lead.operationType === 'BUY' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else if (lead.operationType === 'BUYSELL' || lead.operationType === 'BUY') {
        navigate('/buy-location');
      } else if (lead.operationType === 'SELL') {
        navigate('/sell-location');
      }
    } else if (context.flowOrder === 'Buying Only') {
      if(compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
      navigate('/legal-residence');
      } else if (compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else{
        navigate('/buy-location');
      } 
    } else if (context.flowOrder === 'Selling Only') {
      if(compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
      navigate('/legal-residence');
      } else if (compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else{
        navigate('/sell-location');
      } 
    } 
  };

  const submit = () => {
    navigate('/thankyou');
  };

  return (
    <>
      {lead && (
        <div className={classes.root}>
          <Container id = "lead-form-confirmation-screen">
            <Grid Container className={classes.confirmationPageRoot}>
              <Grid id="lead-form-confirmation-screen-header" item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h2"
                  id="lead-form-confirmation-screen-heading"
                  className={clsx(
                    classes.confirmHeading,
                    classes.internalContent
                  )}
                >
                  {context.screenTitle}
                </Typography>
              </Grid>
              <Grid id="confirmation-screen-detail-card" item xs={12} sm={12} md={12} lg={12}>
                <Card className={classes.confirmCard}>
                  <CardContent>
                    {/* <Button size="small" className={classes.editButton}>
                <EditIcon fontSize="small" className={classes.agentIcon} />
               Edit
               </Button> */}
                    <Typography id="confirmation-screen-customer-info" variant="h4">{context.customerInfo}</Typography>
                    <ul className={classes.ul}>
                      <li className={classes.list}>
                        <Typography id="first-name-label" className={classes.confirmContent}>
                          First Name
                        </Typography>
                        <Typography
                          id="first-name-value"
                          variant="h6"
                          className={classes.confirmContent}
                        >
                          {lead.customerInfo.firstName}
                        </Typography>
                      </li>
                      <li className={classes.list}>
                        <Typography id="last-name-label" className={classes.confirmContent}>
                          Last Name
                        </Typography>
                        <Typography
                          id="last-name-value"
                          variant="h6"
                          className={classes.confirmContent}
                        >
                          {lead.customerInfo.lastName}
                        </Typography>
                      </li>
                      <li className={classes.list}>
                        <Typography id="email-label" className={classes.confirmContent}>
                          Email
                        </Typography>
                        <Typography
                          variant="h6"
                          id="email-value"
                          className={classes.confirmContent}
                        >
                          {lead.customerInfo.email}
                        </Typography>
                      </li>
                      <div>
                        {lead.stateOfResd ? (
                          <li className={classes.list}>
                            <Typography id="state-of-residence-label" className={classes.confirmContent}>
                              State of Residence
                            </Typography>
                            <Typography
                              variant="h6"
                              id="state-of-residence-value"
                              className={classes.confirmContent}
                            >
                              {lead.stateOfResd.name}
                            </Typography>
                          </li>
                        ) : ""}
                      </div>
                      <li className={classes.list}>
                        <Typography id="mobile-label" className={classes.confirmContent}>
                          Mobile
                        </Typography>
                        <Typography
                          variant="h6"
                          id="mobile-value"
                          className={classes.confirmContent}
                        >
                          {lead.customerInfo.phoneNumber.slice(0,3)}-{lead.customerInfo.phoneNumber.slice(3,6)}-{lead.customerInfo.phoneNumber.slice(6)}
                        </Typography>
                      </li>
                      <li className={classes.list}>
                        <Typography id="text-me-label" className={classes.confirmContent}>
                          Text Me
                        </Typography>
                        {lead.customerInfo.textYou ? (
                          <Typography
                            variant="h6"
                            id="text-me-value"
                            className={classes.confirmContent}
                          >
                            Yes
                          </Typography>
                        ) : (
                          <Typography
                            variant="h6"
                            id="text-me-value"
                            className={classes.confirmContent}
                          >
                            No
                          </Typography>
                        )}
                      </li>
                      <>
                        {lead.customQuestion && (
                          <li className={classes.list}>
                            <Typography id="additional-question-label" className={classes.confirmContent}>
                              Employer Name
                            </Typography>
                            <>
                              {lead.customQuestion && lead.customQuestion[0] && lead.customQuestion[0].value ? (
                                <Typography
                                  variant="h6"
                                  id="additional-question-value"
                                  className={classes.confirmContent}
                                >
                                  {lead.customQuestion[0].value}
                                </Typography>
                              ) : (
                                <Typography
                                  variant="h6"
                                  id="additional-quesion-value"
                                  className={classes.confirmContent}
                                >
                                  -
                                </Typography>
                              )}
                            </>
                          </li>
                        )}
                      </>
                    </ul>
                    {/* <Button size="small" className={classes.editButton}>
                <EditIcon fontSize="small" className={classes.agentIcon} />
               Edit
               </Button> */}
                    <Typography id="confirmation-screen-service-info" variant="h4">{context.serviceInfo}</Typography>
                    <ul className={classes.ul}>
                      {((lead.operationType ==='BUY' || lead.operationType === 'BUYSELL') && lead.buyingLocation) && (
                        <>
                          <li className={classes.list}>
                            <Typography id="confirmation-screen-buying-location-label" className={classes.confirmContent}>
                              Buying Location
                            </Typography>
                            <Typography
                              variant="h6"
                              id="confirmation-screen-buying-location-value"
                              className={classes.confirmContent}
                            >
                              {lead.buyingLocation.selectedOption}
                            </Typography>
                          </li>
                        </>
                      )}

                      {((lead.operationType ==='SELL' || lead.operationType === 'BUYSELL') && lead.sellingLocation) && (
                        <>
                          <li className={classes.list}>
                            <Typography id="confirmation-screen-selling-location-label" className={classes.confirmContent}>
                              Selling Location
                            </Typography>
                            <Typography
                              variant="h6"
                              id="confirmation-screen-selling-location-value"
                              className={classes.confirmContent}
                            >
                              {sellAddress}
                            </Typography>
                          </li>
                        </>
                      )}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                className={classes.legalDisclaimer}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ display: 'none' }}
              >
                <FormControl required error={error}>
                  <FormGroup>
                    <FormControlLabel
                      classes={{ label: classes.checkBoxMargin }}
                      control={(
                        <Checkbox
                          className={classes.themeSecondaryColor}
                          checked={ESIGN}
                          id="leadform-esign-checkbox"
                          name="ESIGN"
                          onChange={handleChangeEsign}
                        />
                      )}
                      label={(
                        <div role="dialog" aria-modal="true">
                          <Typography className={classes.checkBoxMargin}>
                            I accept the{" "}
                            <Link id="esign-link" className={classes.textLegal}>
                              {" "}
                              Electronic Records and signature Disclosure and
                              Consent
                            </Link>
                          </Typography>
                        </div>
                      )}
                    />
                    <FormControlLabel
                      classes={{ label: classes.checkBoxMargin }}
                      control={(
                        <Checkbox
                          className={classes.themeSecondaryColor}
                          checked={ABAD}
                          id="leadform-abad-checkbox"
                          name="ABAD"
                          onChange={handleChangeAbad}
                        />
                      )}
                      label={(
                        <div role="dialog" aria-modal="true">
                          <Typography className={classes.checkBoxMargin}>
                            I accept the{" "}
                            <Link id="abad-link" className={classes.textLegal}>
                              {" "}
                              Affiliated Business Arrangement Disclosure
                            </Link>
                          </Typography>
                        </div>
                      )}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.confirmBtn}
              >
                <Button
                  size="large"
                  id="confirmation-screen-goback-btn"
                  className={clsx(globalThemeCss.outlinedBtn, classes.prevButton)}
                  variant="outlined"
                  onClick={GoBack}
                >
                  Go Back
                </Button>
                <Button
                  size="large"
                  // disabled={error}
                  className={globalThemeCss.containedBtn}
                  variant="contained"
                  id="submit-btn"
                  onClick={submit}
                >
                  Submit
                </Button>
              </Grid>
              <Grid className={classes.confirmFooter}>
                <footer id="confirmation-screen-footer">
                  {context.footnotes?.raw
                    && documentToReactComponents(
                      JSON.parse(context.footnotes?.raw)
                    )}
                </footer>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
}
